/* Font Family Css Start */
@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNova-Regular.eot');
    src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('../fonts/ProximaNova-Regular.woff') format('woff'),
        url('../fonts/ProximaNova-Regular.ttf') format('truetype'),
        url('../fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
	font-display: auto;
}
@font-face {
    font-family: 'Proxima Nova Bl';
    src: url('../fonts/ProximaNova-Black.eot');
    src: url('../fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova-Black.woff2') format('woff2'),
        url('../fonts/ProximaNova-Black.woff') format('woff'),
        url('../fonts/ProximaNova-Black.ttf') format('truetype'),
        url('../fonts/ProximaNova-Black.svg#ProximaNova-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display:auto;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNova-Semibold.eot');
    src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
        url('../fonts/ProximaNova-Semibold.woff') format('woff'),
        url('../fonts/ProximaNova-Semibold.ttf') format('truetype'),
        url('../fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
	font-display: auto;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNova-Bold.eot');
    src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('../fonts/ProximaNova-Bold.woff') format('woff'),
        url('../fonts/ProximaNova-Bold.ttf') format('truetype'),
        url('../fonts/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
    font-weight: 800;
    font-style: normal;
	font-display: auto;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNova-Extrabld.eot');
    src: url('../fonts/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova-Extrabld.woff2') format('woff2'),
        url('../fonts/ProximaNova-Extrabld.woff') format('woff'),
        url('../fonts/ProximaNova-Extrabld.ttf') format('truetype'),
        url('../fonts/ProximaNova-Extrabld.svg#ProximaNova-Extrabld') format('svg');
    font-weight: 900;
    font-style: normal;
	font-display: auto;
}
/* Font Family Css End */

/* Common Css Start */
*, body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Proxima Nova', sans-serif;
    font-weight: normal;
}
body{
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
}
.pr-5 {
    padding-right: 5px;
}
.pl-5{
    padding-left: 5px;
}
.d-flex{
    display: flex;
}
.align-center{
    align-items: center;
}
.j-center{
    justify-content: center;
}
.heading{
    font-weight: bold;
    color: #808080;
}
.lightBordered{
    border: 1px solid #ffffff;
}
.d-flex{
    display: flex;
}
.full-width{
    width: 100%;
    float: left;
}
.half-width{
    width: 50%;
    float: left;
}
.third-width{
    width: 33.33%;
    float: left;
}
/* Common Css Ends */

/* Target Tracking Page Css Start */
.cus-main-container{
    width: 100%;
    display: flex;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    .cus-inner-container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        .page-heading{
            width: 100%;
            display: inline-block;
            text-align: center;
            padding: 10px 0;
            h2{
                font-weight: bold;
                font-size: 40px;
                color: #737373;
            }
        }
        .grid-heading{
            width: 100%;
            display: inline-block;
            text-align: center;
            margin-bottom: 7px;
            h3{
                font-size: 30px;
                font-weight: bold;
            }
        }
        .cus-circle-chart{
            width: 100%;
            display: inline-block;
        }
        .cus-per-sec{
            width: 100%;
            display: table;
            height: 250px;
            align-items: center;
            position: relative;
            padding-left: 25px;
            .info{
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 0;
                margin: 0;
                list-style: none;
                height: 100%;
                align-items: center;
                justify-content: center;
                .row{
                    display: inline-block;
                    width: 100%;
                    position: relative;
                    margin: 10px 0;
                    h3{
                        font-weight: bold;
                        font-size: 25px;
                        color: #737373;
                        padding-left: 35px;
                        span {
                            font-weight: bold;
                        }
                    }
                    &:after{
                        content: "";
                        height: 25px;
                        width: 25px;
                        border-radius: 50%;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        -ms-transform: translate(0, -50%);
                        -webkit-transform: translate(0, -50%);
                        -moz-transform: translate(0, -50%);
                        -o-transform: translate(0, -50%);
                        transform: translate(0, -50%);
                        background-color: #00A88E;
                    }
                    &.dot-1 {
                        &:after{
                            background-color: #199883;
                        }
                    }
                    &.dot-2 {
                        &:after{
                            background-color: #7CE495;
                        }
                    }
                    &.dot-3 {
                        &:after{
                            background-color: #00C26E;
                        }
                    }
                }
            }        
        }
        .left-card{
            padding-right: 10px;
            .cus-per-sec{
                padding-left: 0;
                padding-right: 25px;
                .info{
                    .row{
                        h3 {
                            padding-left: 0;
                            padding-right: 40px;
                            text-align: right;
                        }
                        &::after{
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
        }
        .right-card{
            padding-left: 10px;
        }
        .cus-date-sec {
            position: absolute;
            right: 20px;
            top: 35px;
            h3 {
                font-weight: bold;
                font-size: 18px;
                color: #AAAAAA;
            }
        }
        .cus-time-sec {
            position: absolute;
            right: 20px;
            top: 35px;
            h3 {
                font-weight: bold;
                font-size: 18px;
                color: #AAAAAA;
                > div {
                    color: #AAAAAA !important;
                    width: auto !important;
                    display: inline-block;
                    text-transform: uppercase;
                    span{
                        min-width: 95px;
                        display: inline-block;
                        padding: 0 !important;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
.styles_digit__3_I6o{
    min-height: 25px;
    font-size: 35px;
    background-color: #00A88E !important;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 10px 12px;
    height: auto !important;
    line-height: normal !important;
    margin: 0 6px;
    float: left;
    > div{
        left: 0;
        top: 0;
        font-weight: bold;
    }
    .styles_hinge__2iwrZ {
        height: 4px;
        background: rgba(255, 255, 255, 0.7);
    }
    &::after, &::before {
        content: "";
        position: absolute;
        left: -6px;
        background: #00A88E;
        width: 4px;
        height: 16px;
        border-radius: 4px;
        top: 50%;
        -moz-transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }
    &::before {
        left: auto;
        right: -6px;
    }
}
/* Target Tracking Page Css Ends */

/* Transaction Counter Page Css Start */
.part{
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 28px 0;
    flex-direction: column;
    height: 100%;
    h3{
        color: #00A88E !important; 
    }
}
.apple-ring{
    .apple-ring-heading{
        display: flex;
        align-items: center;
        h2{
            font-weight: 600;
            color: #808080;
        }
    }
}
.date-message{
    background: #00a88e;
    padding: 8px 20px;
    color: #ffffff;
    margin: 10px 0 0 0;
    font-size: 20px;
    font-weight: 600;
    // position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.cus-inner-container{
    .cus-middle-sec{
        height: calc(100% - 150px);
    }
    .cus-grid-rep {
        border: 3px solid #737373;
        border-top: none;
        height: 50%;
        &:first-child, &:nth-child(4) {
            border-left: none;
            border-right: none;
        }
        &:last-child {
            border-left: none;
            border-right: none;
        }
        &:nth-child(3) {
            border-right: none;
            border-left: none;
        }
        &:nth-child(4), &:nth-child(5), &:last-child{
            border-bottom: none;
        }
    }
}
/* Transaction Counter Page Css Ends */

/* Circle Chart CSS Here */
.circles-container {
    position: relative;
    height: 230px;
    width: 230px;
    margin: 0 auto;
    .cus-logo {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 40px;
        height: 40px;
        display: inline-block;
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 50%;
        padding: 6px;
        img {
            width: 100%;
            height: 100%;
            display: inline-block;
            object-fit: contain;
        }
    }
}  
.dot {
    z-index: 2;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-transition: transform 400ms;
    -moz-transition: transform 400ms;
    -o-transition: transform 400ms;
    transition: transform 400ms;
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
}  
.dot:after {
    content: '';
    position: absolute;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
    height: 30px; width: 30px;
    background: transparent;
    border-radius: 100%;
    -moz-box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.50);
    -webkit-box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.50);
    box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.50);
}  
.ring {
    position: absolute;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%; left: 50%;
    border-radius: 100%;
}  
.ring:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100%;
}  
.ring:after {
    content: '';
    position: absolute;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%; left: 50%;
    height: 40px; width: 40px;
    background: var(--background);
    border-radius: 100%;
}  
.progress-ring {
    position: absolute;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%; left: 50%;
}
.progress-ring__circle {
    -webkit-transition: stroke-dashoffset 350ms;
    -moz-transition: stroke-dashoffset 350ms;
    -o-transition: stroke-dashoffset 350ms;
    transition: stroke-dashoffset 350ms;
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
    transform-origin: 50% 50%;
}
  
/* Themes Css Here */
.day.ring, .quarter.ring {
    height: 220px;
    width: 220px;
    background: #D1EAE6;
    border: 1px solid #D1EAE6;
}
.week.ring, .halfYearly.ring {
    height: 160px;
    width: 160px;
    background: #E5FAEA;
    border: 1px solid #E5FAEA;
}
.month.ring, .completeYearly.ring {
    height: 100px;
    width: 100px;
    background: #CCF3E2;
    border: 1px solid #CCF3E2;
}

.month.ring:after, .completeYearly.ring:after {
    background: #fff;
}  
.card {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transition: transform 0.5s;
    height: 100%;
    padding: 10px 0;
}
.info{
    .row{
        &.day, &.quarter {
            color: #00A88E;
        }
        &.week, &.halfYearly {
            color: #B1FD36;
        }
        &.month, &.completeYearly {
            color: #15C2E0;
        }
    }
}
.controls {
    display: flex;
    flex-direction: column;
}
.number-input {
    --color: #ffffff;
    border: 1px solid var(--color);
    border-radius: 5px;
    color: var(--color);
    margin-right: 16px;
}  
.number-input span {
    display: block;
    padding: 12px 24px;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid var(--color);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
} 
.number-input .increase:hover,
.number-input .decrease:hover {
    cursor: pointer;
    background: var(--color);
    color: #ffffff;
}
.number-input[data-target="day"], .number-input[data-target="quarter"] {
    --color: #00A88E;
}
.number-input[data-target="week"], .number-input[data-target="halfYearly"] {
    --color: #B1FD36;
}
.number-input[data-target="month"], .number-input[data-target="completeYearly"] {
    --color: #15C2E0;
}
.circles-container{
    .day, .week, .month, .quarter, .halfYearly, .completeYearly{
        &:before{
            content: "";
            color: #fff;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 50%;
            top: 4px;
            border-radius: 50%;
            z-index: 999999;
            -ms-transform: translate(-50%, 0);
            -webkit-transform: translate(-50%, 0);
            -moz-transform: translate(-50%, 0);
            -o-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
            font-size: 32px;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: url("../images/arrow-right.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-color: transparent;
        }
        &.double-arrow{
            &:after{
                content: "";
                color: #fff;
                width: 20px;
                height: 20px;
                position: absolute;
                left: 52%;
                top: 4px;
                border-radius: 50%;
                z-index: 999999;
                -ms-transform: translate(-50%, 0);
                -webkit-transform: translate(-50%, 0);
                -moz-transform: translate(-50%, 0);
                -o-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
                font-size: 32px;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: url("../images/arrow-right.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                background-color: transparent;
            }
        }
    }
}


/* Media Responsive CSS Here */
@media (min-width: 1200px){
    .circles-container {
        height: 290px;
        width: 290px;
        .cus-logo {
            width: 100px;
            height: 100px;
            padding: 15px;
        }
    }
    .day.ring, .quarter.ring {
        height: 280px;
        width: 280px;
    }
    .week.ring, .halfYearly.ring {
        height: 220px;
        width: 220px;
    }
    .month.ring, .completeYearly.ring {
        height: 160px;
        width: 160px;
    }
    .cus-main-container{
        .cus-inner-container{
            .cus-per-sec{
                .info{
                    .row{
                        h3 {
                            font-size: 30px;
                        }
                    }
                }
            }
            .cus-per-sec{
                height: 290px;
            }
        }
    }
    .styles_digit__3_I6o {
        min-height: 40px;
        font-size: 50px;
        padding: 10px 14px;
    }
}
@media (min-width: 1441px){
    .circles-container {
        height: 370px;
        width: 370px;
        .cus-logo {
            width: 175px;
            height: 175px;
            padding: 35px;
        }
    }
    .day.ring, .quarter.ring {
        height: 360px;
        width: 360px;
    }
    .week.ring, .halfYearly.ring {
        height: 300px;
        width: 300px;
    }
    .month.ring, .completeYearly.ring {
        height: 240px;
        width: 240px;
    }
    .cus-main-container{
        .cus-inner-container{
            .page-heading{
                h2 {
                    font-size: 50px;
                }
            }
            .grid-heading{
                h3 {
                    font-size: 40px;
                }
            }
            .cus-date-sec{
                h3 {
                    font-size: 20px;
                }
            }
            .cus-time-sec {
                h3 {
                    font-size: 20px;
                }
            }
            .cus-per-sec{
                height: 370px;
                .info{
                    .row{
                        h3 {
                            font-size: 35px;
                            padding-left: 50px;
                        }
                        &:after {
                            height: 30px;
                            width: 30px;
                        }
                    }
                }
            }
            .left-card{
                .cus-per-sec{
                    .info{
                        .row{
                            h3 {
                                padding-right: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
    .styles_digit__3_I6o {
        min-height: 50px;
        font-size: 60px;
    }
    .date-message {
        padding: 8px 20px;
        font-size: 22px;
    }
}
@media (min-width: 1650px){
    .cus-main-container{
        .cus-inner-container{
            .page-heading{
                h2 {
                    font-size: 60px;
                }
            }
            .grid-heading{
                h3 {
                    font-size: 50px;
                }
            }
            .cus-date-sec{
                h3 {
                    font-size: 24px;
                }
            }
            .cus-time-sec {
                h3 {
                    font-size: 24px;
                }
            }
            .cus-per-sec{
                .info{
                    .row{
                        h3 {
                            font-size: 45px;
                            padding-left: 60px
                        }
                        &:after{
                            height: 35px;
                            width: 35px;
                        }
                    }
                }
            }
            .left-card{
                .cus-per-sec{
                    .info{
                        .row{
                            h3 {
                                padding-right: 60px;
                            }
                        }
                    }
                }
            }
        }
    }
    .styles_digit__3_I6o {
        min-height: 60px;
        font-size: 70px;
    }
    .date-message {
        padding: 10px 20px;
        font-size: 26px;
    }
}